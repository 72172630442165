import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import CloseIcon from "@mui/icons-material/Close";

const Register = ({ logClick, getProfile }) => {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const navigate = useNavigate();
    const [affiliate, setAffiliate] = useState(null);
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    useEffect(() => {
        if (login) {
            navigate("/");
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            const refParam = urlParams.get("ref");
            if (refParam) {
                setAffiliate(refParam);
            }
            if (localStorage.getItem("affiliate")) {
                setAffiliate(localStorage.getItem("affiliate"));
            }
        }
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
    }, []);
    const onSubmit = (data) => {
        const phoneNumberPattern = /^(0\d{9,10})$/;
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự"
            });
            return false;
        }
        if (data.username.length > 11) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối đa 11 kí tự"
            });
            return false;
        }
        if (!regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt"
            });
            return false;
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
            return false;
        }
        if (data.password !== data.ippassword) {
            setError("ippassword", {
                type: "minLength",
                message: "Hai mật khẩu không khớp"
            });
            return false;
        }
        if (!data.name) {
            setError("name", {
                type: "minLength",
                message: "Vui lòng nhập đầy đủ họ tên"
            });
            return false;
        }
        if (data.name.length < 6 || data.name.indexOf(" ") == -1) {
            setError("name", {
                type: "minLength",
                message: "Vui lòng nhập họ tên trùng với thông tin ngân hàng"
            });
            return false;
        }
        if (!data.sdt) {
            setError("sdt", {
                type: "minLength",
                message: "Vui lòng nhập số điện thoại"
            });
            return false;
        }
        if (!phoneNumberPattern.test(data.sdt)) {
            setError("sdt", {
                type: "minLength",
                message: "Định dạng số điện thoại không hợp lệ"
            });
            return false;
        }
        if (data.paypwd.length < 6) {
            setError("paypwd", {
                type: "minLength",
                message: "Mật khẩu rút tiền tối thiểu 6 kí tự"
            });
            return false;
        }
        let formData;
        if (affiliate == null) {
            formData = {
                username: data.username.toLowerCase(),
                password: data.password,
                code: data.code === "" ? "admin" : data.code,
                sdt: data.sdt,
                name: data.name,
                paypwd: data.paypwd
            };
        } else {
            formData = {
                username: data.username.toLowerCase(),
                password: data.password,
                code: affiliate,
                sdt: data.sdt,
                name: data.name,
                paypwd: data.paypwd
            };
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/register`, formData)
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng ký thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    axios
                        .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                            username: data.username.toLowerCase(),
                            password: data.password
                        })
                        .then((res) => {
                            localStorage.removeItem("user");
                            localStorage.removeItem("profile");
                            localStorage.removeItem("data");
                            localStorage.setItem("user", res.data.data);
                            localStorage.setItem("password", data.password);
                            navigate("/?notify");
                        });
                });
            })
            .catch((err) => swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [showPassword1, setShowPassword1] = useState(false);
    const toggleShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };
    const [showPassword2, setShowPassword2] = useState(false);
    const toggleShowPassword2 = () => {
        setShowPassword2(!showPassword2);
    };
    return (
        <>
            <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <img alt="" src="/V9link.png" width="135" className="logo" />
                    <h1>ĐĂNG KÝ</h1>
                </div>
                <div className="inputs">
                    <div className="input">
                        <img alt="" src={require("../../static/i_user.png")} />
                        <input type="text" className="ip-lg" {...register("username", { required: true })} placeholder="Tên đăng nhập" />
                    </div>
                    {errors.username ? <p>{errors.username.message}</p> : null}
                    <div className="input">
                        <img alt="" src={require("../../static/i_pasw.png")} />
                        <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Mật khẩu" />
                        {showPassword ? <Visibility onClick={toggleShowPassword} /> : <VisibilityOff onClick={toggleShowPassword} />}
                    </div>
                    {errors.password ? <p>{errors.password.message}</p> : null}
                    <div className="input">
                        <img alt="" src={require("../../static/i_pasw.png")} />
                        <input type={showPassword1 ? "text" : "password"} className="ip-lg" {...register("ippassword", { required: true })} placeholder="Nhập lại mật khẩu" />
                        {showPassword1 ? <Visibility onClick={toggleShowPassword1} /> : <VisibilityOff onClick={toggleShowPassword1} />}
                    </div>
                    {errors.ippassword ? <p>{errors.ippassword.message}</p> : null}
                    <div className="input">
                        <img alt="" src={require("../../static/i_user.png")} />
                        <input type="text" className="ip-lg" {...register("name", { required: true })} placeholder="Họ và tên" />
                    </div>
                    {errors.name ? <p>{errors.name.message}</p> : null}
                    <div className="input">
                        <img alt="" src={require("../../static/i_phone.png")} />
                        <input type="number" className="ip-lg" {...register("sdt", { required: true })} placeholder="Số điện thoại" />
                    </div>
                    {errors.sdt ? <p>{errors.sdt.message}</p> : null}
                    <div className="input">
                        <img alt="" src={require("../../static/i_safety.png")} />
                        <input type={showPassword2 ? "text" : "password"} className="ip-lg" {...register("paypwd", { required: true })} placeholder="Mật khẩu rút tiền" />
                        {showPassword2 ? <Visibility onClick={toggleShowPassword2} /> : <VisibilityOff onClick={toggleShowPassword2} />}
                    </div>
                    {errors.paypwd ? <p>{errors.paypwd.message}</p> : null}
                    {affiliate == null && (
                        <>
                            <div className="input">
                                <img alt="" src={require("../../static/i_invitation.png")} />
                                <input type="code" className="ip-lg" {...register("code")} placeholder="Mã giới thiệu (nếu có)" />
                            </div>
                            {errors.code ? <p>{errors.code.message}</p> : null}
                        </>
                    )}
                </div>
                {err ? <p>{err}</p> : null}
                <button className="btn-red-big" type="submit">
                    ĐĂNG KÝ NGAY
                </button>
                <div style={{ padding: "15px 0 0" }}>
                    Đã có tài khoản? <a onClick={logClick}>Đăng nhập ngay</a>
                </div>
            </form>
        </>
    );
};
export default Register;
